import { MouseEvent } from 'react';

import { isKakaotalk, useIsToss } from '@common/device-manager';
import { loginKakao, loginToss } from '@common/utils';

import { getUserAccount } from '../graphql/user-account.2';

export function useBottomTabBarLoginService() {
  const isToss = useIsToss();
  const goToLogin = async (event: MouseEvent<HTMLAnchorElement>, redirect_url: string) => {
    const is_login = await isLogin();

    if (is_login) {
      return;
    }

    event.preventDefault();

    if (isKakaotalk()) {
      loginKakao(redirect_url);
      return;
    }

    if (isToss) {
      loginToss();
      return;
    }
  };

  return { goToLogin };
}

const isLogin = async () => {
  try {
    const {
      data: { user_account },
    } = await getUserAccount();
    return Boolean(user_account);
  } catch (error) {
    console.error(error);
    return false;
  }
};
